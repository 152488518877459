var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("admin.notifications.stock.title"))+" ")]),_c('CCardBody',[_c('CDataTable',{attrs:{"fields":_vm.fields,"items":_vm.items,"items-per-page":20,"loading":_vm.isLoading,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","hover":"","pagination":"","striped":""},scopedSlots:_vm._u([{key:"name",fn:function({item}){return [_c('td',{staticClass:"py-2"},[_c('router-link',{attrs:{"to":{
              name: 'admin.restaurants.sections.dishes.edit',
              params: {
                restaurant: item.restaurant_id,
                section: item.section_id,
                dish: item.id
              }
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }