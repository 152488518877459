<template>
  <CSelect @update:value="setValue" :options="options" :value="value" />
</template>

<script>
export default {
  props: ["options", "value"],

  methods: {
    setValue(value) {
      this.$emit("update:value", value);
    }
  }
};
</script>
