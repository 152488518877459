<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.notifications.restaurants.title") }}
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :fields="fields"
          :items="items"
          :items-per-page="20"
          :loading="isLoading"
          :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
          border
          hover
          pagination
          striped
      >
        <template #name="{item}">
          <td class="py-2">
              {{ item.name }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "restaurant", label: this.$t("admin.restaurant") },
        { key: "hidden_who_name", label: this.$t("admin.notifications.hidden_who") },
        { key: "hidden_at", label: this.$t("admin.notifications.hidden_at") }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
          .get(`/api/admin/notifications/restaurants`, {
            params: this.form
          })
          .then(response => {
            this.items = response.data.data;
            this.isLoading = false;
          });
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.notifications.restaurants.title")
    };
  }
};
</script>
