<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.notifications.stock.title") }}
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :fields="fields"
        :items="items"
        :items-per-page="20"
        :loading="isLoading"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        border
        hover
        pagination
        striped
      >
        <template #name="{item}">
          <td class="py-2">
            <router-link
              :to="{
                name: 'admin.restaurants.sections.dishes.edit',
                params: {
                  restaurant: item.restaurant_id,
                  section: item.section_id,
                  dish: item.id
                }
              }"
            >
              {{ item.name }}
            </router-link>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "restaurant", label: this.$t("admin.restaurant") },
        { key: "name", label: this.$t("admin.name") },
        {
          key: "stock_updated_at",
          label: this.$t("admin.restaurants.sections.dishes.stock_updated_at")
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get(`/api/admin/notifications/stock`, {
          params: this.form
        })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.notifications.stock.title")
    };
  }
};
</script>
