<template>
  <div>
    <CRow align-horizontal="center">
      <CCol sm="3">
        <label>{{ $t("admin.notifications.dishes.period") }}</label>
        <Select :options="periods" :value.sync="form.period" />
      </CCol>
    </CRow>
    <CCard>
      <CCardHeader>
        {{ $t("admin.notifications.dishes.title") }}
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :loading="isLoading"
          :items="items"
          :items-per-page="20"
          :fields="fields"
          :noItemsView="{
            noResults: $t('admin.no_results'),
            noItems: $t('admin.no_items')
          }"
          border
          striped
          hover
          pagination
        >
          <template #show_actions="{item}">
            <td class="py-2 text-center">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="revealDish(item.id)"
              >
                <CIcon name="cilOpacity"></CIcon>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Select from "@/components/admin/Select";

export default {
  components: { Select },

  data() {
    return {
      isLoading: true,
      items: [],
      periods: [],
      form: {
        period: null
      },
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "restaurant", label: this.$t("admin.restaurant") },
        { key: "section", label: this.$t("admin.section") },
        { key: "name", label: this.$t("admin.name") },
        {
          key: "hidden_at",
          label: this.$t("admin.notifications.dishes.hidden_at")
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ]
    };
  },

  watch: {
    form: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },

  created() {
    this.periods = Object.entries(
      this.$t("admin.notifications.dishes.periods")
    ).map(([key, value]) => ({ value: key, label: value }));
    this.form.period = this.periods[0].value;
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get(`/api/admin/notifications/dishes`, {
          params: this.form
        })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    revealDish(id) {
      this.isLoading = true;
      this.$http
        .put(`/api/admin/notifications/dishes/${id}`)
        .then(() => this.getData());
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.notifications.dishes.title")
    };
  }
};
</script>
